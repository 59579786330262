<template>
  <div id="index">
    <dv-full-screen-container class="bg" style="overflow: auto;padding-bottom: 200px;">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <!-- <div  v-if="!loading&&showdiv=='0'" class="host-body"> -->
      <div v-if="!loading" class="host-body">
        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex" style="width: 100%">
            <div class="react-left bg-color-blue mr-3">
              <!-- <span class="text fw-b" style="font-size: 30px;color: rgb(255, 193, 7);float: left;margin-left: 2rem;">数据是一面镜子</span> -->
              <span
                class="text fw-b"
                style="
                  font-size: 45px;
                  color: rgb(255, 193, 7);
                  float: left;
                  margin-left: 3rem;
                "
                >旭升帽业车间实时监控</span
              >
              <span
                class="text fw-b"
                style="
                  font-size: 30px;
                  color: rgb(255, 193, 7);
                  float: right;
                  margin-right: 2rem;
                "
                >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
              >
            </div>
          </div>
        </div>

        <div class="body-box" id="showdivdata" style="margin-bottom: 200px;width: 100%;    display: block;">
          <div v-for="(item, index) in selectList" :key="index">
            <div class="bottom-data">
              <div @click="openMainProduct(item)" >
                <span
                  class="fs-xl text mx-2 divleft"
                >
                  {{ item.departmentTitle}}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import { findBigOrderInforList } from "../libs/axios";
import { formatTime } from "../utils/index.js";
export default {
  data() {
    return {
      selectList: [], // 多选数据
      loading: true,
      showdiv: "0",
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      // 添加或编辑表单对象初始化数据
      lineGuids: "",
      workShopName: "",
    };
  },
  components: {},
  mounted() {
    this.init();
    this.timeFn();
  },
  methods: {
    openMainProduct(v) {
      if(v.supplierType==0)
      {
         window.open(
          "//121.43.135.74:3403/groupbigscreen?workId=" +
            v.id +
            "&workName=" +
            v.departmentTitle
        );
      }
       if(v.supplierType==1)
      {
         window.open(
          "//121.43.135.74:3403/workbigscreen?workId=" +
            v.id +
            "&workName=" +
            v.departmentTitle
        );
      }
      if(v.supplierType==2)
      {
         window.open(
          "//121.43.135.74:3403/workbigscreen?workId=" +
            v.id +
            "&workName=" +
            v.departmentTitle
        );
      }
    },
    init() {
      this.getWorkGroupList();
    },
    timeFn() {
      setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    getWorkGroupList() {
      findBigOrderInforList().then((res) => {
        this.loading = false;
        this.selectList = res.data;
      });
    },
  },
};
</script>

<style lang="scss">
@import "./groupview/index.scss";
</style>